import React, { useEffect } from "react"
import { useInputChange } from "../custom-hook/useForm"
import RadioButton from "./accountRadioInput"
import "../assets/styles/components/HasMultipleBeneficial.scss"

const HasMultipleBeneficial = (props: { [keys: string]: any }) => {
  const { setIsDisabled, setFormInput } = props

  const [input, handleInputChange] = useInputChange()

  const isCompleted = !input.multipleBeneficial

  useEffect(() => {
    setIsDisabled(isCompleted)
    if (Object.keys(input).length != 0) setFormInput(input)
  }, [isCompleted, input])

  return (
    <div className="multiple-beneficial">
      <h3 className="h3-heading">Are there any other beneficial owners?</h3>
      <span className="desc-1">
        A beneficial owner is anyone who directly or indirectly owns or controls 25% or more of the business.
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </span>
      <div className="multiple-beneficial-form">
        <RadioButton
          name="multipleBeneficial"
          value="yes"
          checked={input.multipleBeneficial === "yes"}
          onChange={handleInputChange}
        >
          Yes
        </RadioButton>
        <RadioButton
          name="multipleBeneficial"
          value="no"
          checked={input.multipleBeneficial === "no"}
          onChange={handleInputChange}
        >
          No
        </RadioButton>
      </div>
    </div>
  )
}

export default HasMultipleBeneficial
